'use client';
import { useState, useEffect, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { LoginOutlined, LogoutOutlined, ShoppingOutlined, UserOutlined } from '@ant-design/icons';
import { useStateContext } from '@/context/StateContext';
import { signIn, signOut } from 'next-auth/react';
import { Tooltip } from 'antd';

const NavBar = dynamic(() => import('./NavBar'), { ssr: false });
const DynamicCart = dynamic(() => import('@/components/Cart'), { ssr: false });

const Header = ({ session, initialIsWhiteBackground }) => {
  const pathname = usePathname();
  const [isMounted, setIsMounted] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const { showCart, setShowCart, totalQuantities } = useStateContext();

  useEffect(() => {
    setIsMounted(true);
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
      if (window.innerWidth >= 992) {
        setIsMobileNavOpen(false);
      }
    };
    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isWhiteBackground = [
    '/about',
    '/bimi-checker',
    '/dmarc-inspector',
    '/vmc-validator',
  ].includes(pathname);

  const [isDropdownOpen, setIsDropdownOpen] = useState({
    Products: false,
    Resources: false,
  });

  const handleMouseEnter = (name) => setIsDropdownOpen((prev) => ({ ...prev, [name]: true }));
  const handleMouseLeave = (name) => setIsDropdownOpen((prev) => ({ ...prev, [name]: false }));

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen((prev) => !prev);
  };

  const linkClass = isWhiteBackground
    ? 'header-nav-link on-white w-nav-link'
    : 'header-nav-link white w-nav-link';

  const navClass = isWhiteBackground
    ? 'position-absolute full header-bg'
    : 'position-absolute full header-bg dark';

  const logoClass = isWhiteBackground ? '-light' : '';

  const loginClass = isWhiteBackground
    ? 'header-nav-link login hidden-on-tablet w-inline-block'
    : 'header-nav-link login hidden-on-tablet white w-inline-block';

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const newOpacity = Math.min(window.scrollY / 20, 1);
      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const memoizedNavBar = useMemo(
    () => (
      <NavBar
        linkClass={linkClass}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        isMobileNavOpen={isMobileNavOpen}
        handleMobileNavToggle={handleMobileNavToggle}
        isDesktop={isDesktop}
      />
    ),
    [linkClass, isDropdownOpen, isMobileNavOpen, isDesktop]
  );

  if (!isMounted) {
    return null; // Return null instead of HeaderSkeleton
  }

  return (
    <div role="banner" className="header-wrapper w-nav">
      <div className="position-relative z-index-1">
        <div className="container-default w-container">
          <div className="header-content-wrapper">
            <Link href="/" className="header-logo-link w-nav-brand">
              <Image
                src={`/images/logo${logoClass}.svg`}
                alt="Brandymail.com logo!"
                className="header-logo"
                width={150}
                height={50}
              />
            </Link>
            <div className="header-middle">{memoizedNavBar}</div>
            <div className="header-right-side">
              <Tooltip placement="bottom" title="View your shopping cart">
                <Link href="#" className={loginClass} onClick={() => setShowCart(true)}>
                  <div style={{ position: 'relative' }}>
                    <ShoppingOutlined
                      className="color-accent-1"
                      style={{ fontSize: '24px', marginRight: '12px' }}
                    />
                    {totalQuantities > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-4px',
                          right: '8px',
                          height: '8px',
                          width: '8px',
                          borderRadius: '50%',
                          backgroundColor: 'green',
                        }}
                      ></div>
                    )}
                  </div>
                </Link>
              </Tooltip>
              {showCart && <DynamicCart />}
              {!session && (
                <div>
                  <Tooltip placement="bottom" title="Sign in to your account">
                    <Link href="#" className={loginClass} onClick={() => signIn()}>
                      <LoginOutlined className="color-accent-1" style={{ fontSize: '24px' }} />
                    </Link>
                  </Tooltip>
                </div>
              )}
              {session && (
                <>
                  <div>
                    <Tooltip placement="bottom" title="Manage my account">
                      <Link href="/account/dashboard" className={loginClass}>
                        <UserOutlined
                          className="color-accent-1"
                          style={{ fontSize: '24px', marginRight: '12px' }}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip
                      placement="bottom"
                      title={`Signed in as ${session.user.email}, click here to sign out`}
                    >
                      <Link href="#" className={loginClass} onClick={() => signOut()}>
                        <LogoutOutlined className="color-accent-1" style={{ fontSize: '24px' }} />
                      </Link>
                    </Tooltip>
                  </div>
                </>
              )}
              <div
                className={`hamburger-menu-wrapper w-nav-button ${
                  isMobileNavOpen ? 'w--open' : ''
                }`}
                onClick={handleMobileNavToggle}
              >
                <div className="hamburger-menu-icon-wrapper">
                  <div
                    className="hamburger-menu-bar top"
                    style={
                      isMobileNavOpen
                        ? {
                            transform:
                              'translate3d(0px, 7px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(135deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }
                        : {}
                    }
                  ></div>
                  <div
                    className="hamburger-menu-bar bottom"
                    style={
                      isMobileNavOpen
                        ? {
                            transform:
                              'translate3d(0px, -6px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }
                        : {}
                    }
                  ></div>
                </div>
              </div>
              <a
                href="/products/digicert-verified-mark-certificate"
                className="btn-primary small header-btn-hidde-on-tablet w-button"
                rel="noreferrer"
              >
                Buy Now
              </a>
            </div>
          </div>
        </div>
        <div className={navClass} style={{ opacity: opacity }}></div>
      </div>
      {/* Mobile Nav Overlay */}
      {isMounted && (
        <div
          className={`w-nav-overlay ${isMobileNavOpen ? 'w--nav-overlay-open' : ''}`}
          style={{
            display: isMobileNavOpen ? 'block' : 'none',
            height: isMobileNavOpen ? '100vh' : '0',
          }}
        >
          {memoizedNavBar}
        </div>
      )}
    </div>
  );
};

export default Header;